import { Link } from "gatsby";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BsFillPersonFill } from "react-icons/bs";
import { FaHeart, FaMapMarkerAlt, FaRegHeart, FaTrain } from "react-icons/fa";
import CartContext from "../context/CartContext";
import CardPillTagSection from "./card-pill-tag-section";
import ImageSlider from "./image-slider";
import PopupForm from "./popup-form";
import { Button, Modal } from "react-bootstrap";
import ExpertCard from "./expert-card";
import ExpertCardPopup from "./expert-card-popup";
import { MdOutlineClear } from "react-icons/md";
import PopupFormViewing from "./popup-form-viewing";

const slugify = require("slugify");
function round5(x) {
	return Math.ceil(x / 5) * 5;
}
const ListingsPropertyCard = ({ property, index }) => {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
		budgetPerDesk,
		numberOfPeople,
	} = React.useContext(CartContext);

	var floors = [];
	if (property?.node?.floorsAvailable !== null) {
		floors =
			property?.node?.floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}

	// const refTb = useRef();
	// const tbShow = useOnScreen(refTb, "0px");
	// useEffect(() => {
	// 	onLocationChangeScroll(
	// 		property.node.locationLatitude,
	// 		property.node.locationLongitude
	// 	);
	// }, [tbShow]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	const handleHeartClick = (clickEvent, shortlistProp, addOrRemove) => {
		clickEvent.preventDefault();
		addOrRemove(shortlistProp);
	};

	const [vidShow, setVidShow] = useState(false);
	const [formShow, setFormShow] = useState(false);

	const handleVidShow = () => setVidShow(true);
	const handleVidClose = () => setVidShow(false);

	const handleFormShow = () => setFormShow(true);
	const handleFormClose = () => setFormShow(false);

	const lowerCapacity = Math.max(
		property?.node?.desksFrom,
		numberOfPeople?.min
	);

	return (
		<div className="bg-white h-100 px-0 mx-0 position-relative">
			<Modal
				style={{ zIndex: 999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="xl"
				show={vidShow}
				centered
				onHide={handleVidClose}
				className=" w-100 p-0 rounded "
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleVidClose}
						/>
					</div>
					<Modal.Body
						style={{ overflow: "hidden" }}
						className="p-0 w-100 rounded "
						closeButton
					>
						<div className="py-3 px-3 px-md-4 px-lg-5 mb-2 bg-white ">
							<Row>
								<Col>
									<h3 className="pb-0 ">
										Book a viewing of{" "}
										<span className="text-dark-blue fw-bold">
											{property?.node.name}
										</span>
									</h3>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>
										Explore our instantly available office spaces at your
										convenience and schedule a flexible viewing today to find
										your ideal workspace.
									</p>
								</Col>
							</Row>
							<PopupFormViewing
								formPropertyName={property?.node.name}
								subject="Book a viewing"
							/>
						</div>
						<ExpertCardPopup />
					</Modal.Body>
				</div>
			</Modal>
			<Modal
				className=" w-100 p-0 rounded "
				style={{ zIndex: 99999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="xl"
				show={formShow}
				onHide={handleFormClose}
				centered
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleFormClose}
						/>
					</div>

					<Modal.Body
						style={{ overflow: "hidden" }}
						className="p-0 w-100 rounded "
						closeButton
					>
						<div className="py-3 px-3 px-md-4 px-lg-5 mb-2 bg-white ">
							<Row>
								<Col>
									<h3 className="pb-0 ">
										Get a quote for{" "}
										<span className="text-dark-blue fw-bold">
											{property?.node.name}
										</span>
									</h3>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>
										Contact us today to receive a personalized quote and find
										your ideal workspace.
									</p>
								</Col>
							</Row>
							<PopupForm
								formPropertyName={property?.node.name}
								subject="Get a quote"
							/>
						</div>
						<ExpertCardPopup />
					</Modal.Body>
				</div>
			</Modal>{" "}
			<div
				className="bg-white h-100 px-0 mx-0 listings-property-card-shadow"
				onMouseEnter={() => {
					onLocationChangeScroll(
						property?.node.locationLatitude,
						property?.node.locationLongitude
					);
				}}
			>
				<Link
					to={`/listings/${slugify(property?.node?.name)}-${
						property?.node?.fixedId
					}`}
				>
					<div>
						<Row
							className="px-0 mx-0 g-0"
							style={{ borderBottom: "1px solid #f5f5f5" }}
						>
							<Col lg={6}>
								<ImageSlider
									imageDataArr={property?.node.photos}
									height={
										selectedLocation?.split(",")[0].length > 20
											? "400px"
											: "360px"
									}
								/>
							</Col>
							<Col
								className="px-3 px-lg-4 pt-2 pt-xl-4 pb-1 text-black"
								style={{ minHeight: "100%" }}
								lg={6}
							>
								<div
									style={{ fontSize: "90%" }}
									className="d-flex align-items-top text-med-grey pb-3 pb-xl-4 justify-content-between"
								>
									<div className="d-flex align-items-center">
										<p className="d-inline-block pb-0 mb-0  ">
											<FaMapMarkerAlt className="me-2" />
											{selectedLocation?.split(",")[0] || "London"} -{" "}
											{(
												getDistanceFromLatLonInKm(
													distancePoint?.latitude,
													distancePoint?.longitude,
													property?.node.locationLatitude,
													property?.node.locationLongitude
												) * 0.621371
											).toFixed(2)}{" "}
											mi
										</p>
									</div>
									<div className="pe-1" style={{ zIndex: 2 }}>
										{cart?.filter(
											(e) => e.airtableId === property?.node.airtableId
										).length > 0 && (
											<FaHeart
												style={{ cursor: "pointer", zIndex: "1" }}
												onClick={(e) =>
													handleHeartClick(e, property.node, OnRemoveFromCart)
												}
												// OnRemoveFromCart(property.node)}
												className="fs-5 text-dark-blue pb-0"
											/>
										)}
										{cart?.filter(
											(e) => e.airtableId === property?.node.airtableId
										).length < 1 && (
											<>
												<FaRegHeart
													style={{ cursor: "pointer", zIndex: "1" }}
													onClick={(e) =>
														handleHeartClick(e, property.node, OnAddToCart)
													}
													// onClick={() => OnAddToCart(property?.node)}
													className="fs-5 text-dark-blue"
												/>
											</>
										)}
									</div>
								</div>

								<h3 className="fs-4 pb-2">{property?.node.name}</h3>
								{slugify(property?.node.city).toLowerCase() === "london" && (
									<div>
										{property?.node.subwayStation1 !== null && (
											<div
												style={{ fontSize: "90%" }}
												className="d-flex align-items-center  pb-3"
											>
												<FaTrain className="me-2" />
												<p className="d-inline-block pb-0 mb-0">
													{property?.node.subwayStation1} -{" "}
													{(
														property?.node.subwayStation1DistanceKm * 0.621371
													).toFixed(2)}
													mi{" "}
													<span className="d-none d-lg-inline-block">
														({property?.node.subwayStation1DurationMins} walk)
													</span>
												</p>
											</div>
										)}
										{property?.node.subwayStation2 !== null && (
											<div
												style={{ fontSize: "90%" }}
												className="d-none d-lg-flex align-items-center pb-3 mb-2"
											>
												<FaTrain className="me-2" />
												<p className="d-inline-block pb-0 mb-0">
													{property?.node.subwayStation2} -{" "}
													{(
														property?.node.subwayStation2DistanceKm * 0.621371
													).toFixed(2)}
													mi ({property?.node.subwayStation2DurationMins} walk)
												</p>
											</div>
										)}
									</div>
								)}
								<div
									style={{ fontSize: "90%" }}
									className="align-items-center pb-3 mb-2"
								>
									<BsFillPersonFill className="me-2" />
									<p className="d-inline-block pb-0 mb-0">
										Up to {property?.node.desksTo} desks available
									</p>
								</div>
								<blockquote>
									{property?.node.type === "Serviced Office" &&
										budgetPerDesk?.perDesk && (
											<>
												<p className="mb-0">Private offices from</p>
												<h5 className="mt-1 fs-4">
													£
													{`${
														property?.node.rentPM === null ||
														property?.node.rentPM === undefined
															? "POA"
															: `${round5(property?.node.rentPM).toLocaleString(
																	"en-UK"
															  )}pcm`
													}`}
												</h5>
											</>
										)}
									{property?.node.type === "Serviced Office" &&
										!budgetPerDesk?.perDesk && (
											<>
												<p className="mb-0">Private offices from</p>
												<h5 className="mt-1 fs-4">
													£
													{`${
														property?.node.rentPM === null ||
														property?.node.rentPM === undefined
															? "POA"
															: `${round5(
																	property?.node.rentPM * lowerCapacity
															  ).toLocaleString("en-UK")}pcm`
													}`}
												</h5>
											</>
										)}
									{(property?.node.type === "Managed Office" ||
										property?.node.type === "Flex Lease") && (
										<>
											<p className="mb-0">Private floors from</p>
											<h5 className="mt-1 fs-4">
												£
												{`${
													floors[0]?.rentPM === null ||
													floors[0]?.rentPM === undefined
														? "POA"
														: `${round5(floors[0]?.rentPM).toLocaleString(
																"en-UK"
														  )}pcm`
												}`}
											</h5>
										</>
									)}
								</blockquote>
							</Col>
						</Row>
						<Row className="pb-0 pt-0 my-2 align-items-center">
							<CardPillTagSection features={property?.node.features} />
						</Row>
					</div>
				</Link>
				<div style={{ height: "70px" }} className="w-100"></div>
				<div className="position-absolute  pb-4 w-100 start-0 bottom-0">
					<Row>
						<Col className="px-4 mt-3  d-md-flex justify-content-between">
							<Button
								onClick={handleFormShow}
								className={`text-white btn btn-primary  me-md-3  w-100 w-md-50 fw-bold `}
							>
								Get a quote
							</Button>
							<Button
								onClick={handleVidShow}
								className={`text-white mt-3 mt-md-0 btn btn-dark-blue   w-100 w-md-50 fw-bold `}
							>
								Book a viewing
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default ListingsPropertyCard;
